import { STAGES, ARCHIVES_STAGES, CLOSE_STEPS, CLOSED_LOST_STEPS, ARCHIVE_LOST_STEPS } from '~/constants'
import type { Lead } from '~/types'

const useLeadStatus = (lead: Ref<Lead | undefined>) => {
  const isShadow = computed(() => {
    return Boolean(lead.value?.parent_id)
  })

  const inInArchive = computed(() => {
    if (!lead.value?.stage.code) return false

    const array = [Object.values(ARCHIVES_STAGES), Object.values(ARCHIVE_LOST_STEPS)].flat()

    if (!array.includes(lead.value.stage.code)) {
      return false
    }

    if (!lead.value.step?.code) return false

    return array.includes(lead.value.step?.code)
  })

  const isClosedWon = computed(() => {
    if (!lead.value?.stage.code) return false

    if (![STAGES.CLOSED, ARCHIVES_STAGES.WON].includes(lead.value.stage.code)) {
      return false
    }

    return lead.value.step?.code === CLOSE_STEPS.WON || lead.value.step?.code === ARCHIVES_STAGES.WON
  })

  const isClosedLost = computed(() => {
    if (!lead.value?.stage.code) return false

    if (![STAGES.CLOSED, ARCHIVES_STAGES.LOST].includes(lead.value.stage.code)) {
      return false
    }

    if (!lead.value.step?.code) return false

    const array = [Object.values(CLOSED_LOST_STEPS), Object.values(ARCHIVE_LOST_STEPS)].flat()

    return array.includes(lead.value.step?.code)
  })

  const isClosed = computed(() => {
    return isClosedWon.value || isClosedLost.value
  })

  return {
    isShadow,
    isClosedWon,
    isClosedLost,
    isClosed,
    inInArchive,
  }
}

export default useLeadStatus
